import React from 'react'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import Image from 'components/shared/ImageGatsby'

import styled, { css } from 'styled-components'

const StyledProjectGrid = styled.section`
  position: relative;
`

const ImageContainer = styled.div`
  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media (min-width: 576px) {
      grid-gap: 10px;
    }
    @media (max-width: 575px) {
      grid-gap: 2px;
    }
  }
`

const GridImage = styled.img<{
  initialHeight: number
  initialWidth: number
}>`
  ${(props) =>
    props.initialHeight > props.initialWidth
      ? css``
      : css`
          grid-column: span 2;
        `}
  object-fit: cover !important;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  max-height: 600px;
  @media (max-width: 767px) {
    max-height: 200px;
  }
`

interface ImageGalleryProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ImageGallery
  location?: any
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ fields }) => {
  const options = {}

  return (
    <>
      <SimpleReactLightbox>
        <StyledProjectGrid className="position-relative container mb-5">
          <ImageContainer>
            <SRLWrapper options={options}>
              {fields.images?.map((image, index: number) => (
                <GridImage
                  loading="lazy"
                  className="w-100 h-100"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  src={image?.localFile?.publicURL}
                  initialHeight={
                    image?.localFile?.childImageSharp?.gatsbyImageData
                      .height
                  }
                  initialWidth={
                    image?.localFile?.childImageSharp?.gatsbyImageData
                      .width
                  }
                />
              ))}
            </SRLWrapper>
          </ImageContainer>
        </StyledProjectGrid>
      </SimpleReactLightbox>
    </>
  )
}

export default ImageGallery
